import React from "react"

import styled from "@emotion/styled"

import { colours, typographyStyles, breakpoints } from "design-kit"

import Footer from "../Shared/Footer"
import Hero from "../Shared/Hero"
import ImageSection from "../Shared/ImageSection"
import Navbar from "../Shared/Navbar"
import {
  HeroHeading as DefaultHeroHeading,
  Main,
  Paragraph,
  Props,
  SectionHeading,
  colors,
} from "../Shared"

import devsImg from "./assets/devs.jpg"
import officeImg from "./assets/office.jpg"
import saniaImg from "./assets/sania.jpg"
import sweatingImg from "./assets/sweating.jpg"
import willImg from "./assets/will.jpg"

const HeroHeading = styled(DefaultHeroHeading)`
  width: 100%;
`

const TallImageSection = styled(ImageSection)`
  ${breakpoints.tablet`
    min-height: 795px;
  `}
`

const CommentSection = styled.section<{
  color: string
  backgroundColor: string
}>`
  padding: 64px 24px;

  ${props => `
    color: ${props.color};
    background-color: ${props.backgroundColor};
  `}

  ${breakpoints.tablet`
    padding: 104px 48px;
  `}
`

const Comment = styled.h3`
  ${typographyStyles.headline2};
  max-width: 1136px;
  margin: auto;

  ${breakpoints.tablet`
    ${typographyStyles.headline3};
  `}
`

const Author = styled(Comment)`
  margin-top: 16px;
`

const View: React.FunctionComponent<Props> = ({ location }) => (
  <React.Fragment>
    <Navbar location={location} />

    <Main>
      <Hero backgroundImg={officeImg}>
        <HeroHeading>Our culture is built on four values</HeroHeading>
      </Hero>

      <TallImageSection variant="normal" image={sweatingImg}>
        <SectionHeading>Empathise with purpose</SectionHeading>

        <Paragraph>
          <b>Understanding unites us and makes us strong.</b>
        </Paragraph>

        <Paragraph>
          Empathy directs both what we build and how we build it.
        </Paragraph>

        <Paragraph>
          We see empathy as a strategic tool, our critical competitive edge. It
          shows us what our customers need – be it something measurable like a
          fast process, or more subtle like emotional security. What our
          customers need become our goals as a business.
        </Paragraph>

        <Paragraph>
          And because we’re all united for the customer, we’re bound together as
          a team and trust that we’re on the same side. That frees us to have
          daring ideas and push and challenge each other. That’s how we do our
          best work.
        </Paragraph>
      </TallImageSection>

      <CommentSection color={colours.white} backgroundColor={colours.offBlack}>
        <Comment>
          “I cannot believe how friendly, professional, efficient, reassuring
          and helpful the whole team is at Habito.”
        </Comment>
        <Author>Hannah</Author>
      </CommentSection>

      <TallImageSection
        variant="reverse"
        image={devsImg}
        backgroundColor={colors.aquamarine}
      >
        <SectionHeading>Build better things</SectionHeading>

        <Paragraph>
          <b>Our ideal product = our customer’s ideal experience.</b>
        </Paragraph>

        <Paragraph>
          We don’t build things based on what capacity we have now. We build for
          our biggest goals and evolve fearlessly to meet them.
        </Paragraph>

        <Paragraph>
          We build for customers, not our egos. We build some things we know are
          vital – but that our customers will never see. We always choose a
          simple, elegant solution over a showy, complex one.
        </Paragraph>

        <Paragraph>
          Sometimes, building better things means choosing not to build. We’re
          not afraid of ideas from the outside and take the best of other
          companies and disciplines. Just as we share our own ideas with other
          companies to build better things together.
        </Paragraph>
      </TallImageSection>

      <CommentSection color={colours.offBlack} backgroundColor={colors.yellow}>
        <Comment>
          “Great use of tech, and fantastic humans at the important points!
          Great user experience upfront… then handed over to knowledgeable,
          friendly staff to deal with the nitty gritty.”
        </Comment>
        <Author>James</Author>
      </CommentSection>

      <TallImageSection
        variant="normal"
        image={saniaImg}
        backgroundColor={colors.gray}
      >
        <SectionHeading>Be 100% responsible</SectionHeading>

        <Paragraph>
          <b>Your loss is my loss. My win is your win.</b>
        </Paragraph>

        <Paragraph>
          We take on personal responsibility, in both the small and the huge. We
          don't leave ringing phones unanswered. We never leave a teammate to
          struggle alone. We actively look for ways to help each other succeed.
        </Paragraph>

        <Paragraph>
          We welcome debate and dissent that pushes us to be better. No question
          is off limits. And no answer should be secret.
        </Paragraph>

        <Paragraph>
          We commit to being 100% responsible in every interaction with
          everyone: customer, teammate, or agency partner. We hope this inspires
          everyone and every business we work with to do the same.
        </Paragraph>
      </TallImageSection>

      <CommentSection color={colours.white} backgroundColor={colours.offBlack}>
        <Comment>
          "The support offered by every member of the team has been exceptional
          to say the least, their commitment to act on my behalf is second to
          none."
        </Comment>
        <Author>Will</Author>
      </CommentSection>

      <TallImageSection
        variant="reverse"
        image={willImg}
        backgroundColor={colors.yellow}
      >
        <SectionHeading>Have backbone</SectionHeading>

        <Paragraph>
          <b>
            Fearless in everything: from speaking up, to backing down, to
            shaking a whole industry.
          </b>
        </Paragraph>

        <Paragraph>
          We're taking the largest, longest-standing, most complex financial
          service structures in the world... and turning them upside-down. This
          is not for the faint-hearted. To do it we need backbone, and lots of
          it.
        </Paragraph>

        <Paragraph>
          Having backbone means speaking up – both when you disagree with
          something, and when you make a mistake. It’s the courage to stand by
          your convictions and the strength of character to listen, learn and
          change your mind.
        </Paragraph>

        <Paragraph>
          It’s our commitment to each other that’s the backbone of this company.
        </Paragraph>
      </TallImageSection>

      <CommentSection
        color={colours.offBlack}
        backgroundColor={colors.aquamarine}
      >
        <Comment>“Patrick fought for my application like a tiger!!!”</Comment>
        <Author>Helen</Author>
      </CommentSection>
    </Main>

    <Footer />
  </React.Fragment>
)

export default View
